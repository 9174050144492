import clsx from "clsx";
import type { NavigationItem } from "../../types";
import NavigationMenuItem from "../NavigationMenuItem/NavigationMenuItem";

type Props = {
  items: NavigationItem[];
  className?: string;
  id?: string;
  floating: boolean;
};
const NavigationMenuItems = ({ items, className, id, floating }: Props) => {
  return (
    <ul id={id} className={clsx(className)}>
      {items.map((item, index) => (
        <NavigationMenuItem data={item} floating={floating} key={index} />
      ))}
    </ul>
  );
};

export default NavigationMenuItems;
